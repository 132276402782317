import React, { useState } from "react";
import TableComponent from "./ReUsableComponents/TableComponent";
import CreateCountryModal from "./CreateCountryModal";
import toast from "react-hot-toast";
import UpdateCountryModal from "./UpdateCountryModal";

function ViewCountryModal({ item, isOpen, onClose, mutate, setMutate }) {
  const [isModalOpen, setisModalOpen] = useState(false);
  const [iseModalOpen, setiseModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const handleCreateCountry = async (formData) => {
    const API_ENDPOINT = `https://main.majorlink.co/api/admin/services/country/${item?.id}`;

    let isLoading = true;

    const userJson = localStorage.getItem("user");
    if (!userJson) {
      console.error("User object not found in local storage");
      isLoading = false;
      toast.error("Authentication error. Please login again.");
      return;
    }

    const user = JSON.parse(userJson);
    const token = user.token;

    if (!token) {
      console.error("Token not found in user object");
      isLoading = false;
      toast.error("Token missing. Please login again.");
      return;
    }
    const loadingToastId = toast.loading("Creating country...");
    try {
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setisModalOpen(false);
        toast.success("Country created successfully!", { id: loadingToastId });
        setMutate(!mutate)
      } else {
        const errorMessage = await response.text();
        toast.error(`Error: ${errorMessage}`, { id: loadingToastId });
      }
    } catch (error) {
      console.error("There was an error sending the request:", error);
      toast.error("Failed to create Country. Please try again.");
    }
  };

  const handleUpdateCountry = async (formData) => {
    const API_ENDPOINT = `https://main.majorlink.co/api/admin/services/country/${selectedItem?.id}`;

    let isLoading = true;

    const userJson = localStorage.getItem("user");
    if (!userJson) {
      console.error("User object not found in local storage");
      isLoading = false;
      toast.error("Authentication error. Please login again.");
      return;
    }

    const user = JSON.parse(userJson);
    const token = user.token;

    if (!token) {
      console.error("Token not found in user object");
      isLoading = false;
      toast.error("Token missing. Please login again.");
      return;
    }
    const loadingToastId = toast.loading("Updating country...");
    try {
      const response = await fetch(API_ENDPOINT, {
        method: "PATCH",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {

        const data = await response.json();
        setisModalOpen(false);
        toast.success("Country updated successfully!", { id: loadingToastId });
        setMutate(!mutate)
      } else {
        const errorMessage = await response.text();
        toast.error(`Error: ${errorMessage}`, { id: loadingToastId });
      }
    } catch (error) {
      console.error("There was an error sending the request:", error);
      toast.error("Failed to update Country. Please try again.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-25">
      <div className="bg-white rounded-lg p-6 w-full max-w-3xl border border-[#f2f2f2]">
        <div>
          <img src={item.imageurl} alt="giftcard" className="w-20" />
          <span>{item?.name} Giftcard</span>
        </div>

        <CreateCountryModal
          isOpen={isModalOpen}
          onClose={() => setisModalOpen(false)}
          onSubmit={handleCreateCountry}
        />

        <UpdateCountryModal
          isOpen={iseModalOpen}
          onClose={() => setiseModalOpen(false)}
          onSubmit={handleUpdateCountry}
          item={selectedItem}
        />

        <div className="flex items-center justify-between mt-8">
          <div
            onClick={() => setisModalOpen(true)}
            className="bg-[#2752E7] text-white py-2 px-10 w-fit cursor-pointer text-[14px]"
          >
            Create new country
          </div>
          <div
            onClick={onClose}
            className="border-[#2752E7] border text-[#2752E7] py-2 px-10 w-fit cursor-pointer text-[14px]"
          >
            Close
          </div>
        </div>
        <TableComponent
          headerName="Countries"
          filters={[]}
          columns={[
            {
              key: "name",
              label: "Name",
              render: (item) => (
                <span
                  className="flex items-center space-x-2"
                  style={{ color: "#262626" }}
                >
                  {item?.name}
                </span>
              ),
            },
            {
              key: "buy",
              label: "Buy Rate",
              render: (item) => (
                <span
                  className="flex items-center space-x-2"
                  style={{ color: "#262626" }}
                >
                  <h1>{item.buy}</h1>
                </span>
              ),
            },
            {
              key: "sell",
              label: "Sell Rate",
              render: (item) => (
                <span
                  className="flex items-center space-x-2"
                  style={{ color: "#262626" }}
                >
                  <h1>{item.sell}</h1>
                </span>
              ),
            },
            // {
            //   key: "updateddate",
            //   label: "Updated Date",
            //   render: (item) => (
            //     <span style={{ color: "#262626" }}>
            //       {item.updated_at ? useFormatDate(item.updated_at) : "N/A"}
            //     </span>
            //   ),
            // },
            {
              key: "action",
              label: "",
              render: (item) => (
                <span
                  className="underline"
                  style={{ color: "#262626" }}
                  onClick={() => {
                    setSelectedItem(item);
                    setiseModalOpen(true);
                  }}
                >
                  Update
                </span>
              ),
            },
            // ... add other columns as needed
          ]}
          data={item?.countries || []}
          isLoading={false}
          //   error={derror}
          //onSelectRow={(item) => (onidselect(item), select(4))}
        />
      </div>
    </div>
  );
}

export default ViewCountryModal;
